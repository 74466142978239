/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import ModalDialog from 'javascripts/vendors/modal_dialog';
import axios from 'axios';
import { tempAttachmentsPath } from 'javascripts/routes';
import i18n from 'javascripts/i18n';

class FormAttachments {
  initVariables(modalDialogHolderRole) {
    this.formInputsHolder = $(this.formAttachmentInputHolderSelector);
    this.newAttachmentModalDialog = $(modalDialogHolderRole);
    this.index = parseInt(this.formInputsHolder.attr('data-index'), 10);
    this.realAttachmentInputs = this.newAttachmentModalDialog
      .find(this.formRealAttachmentFieldsSelector);
    this.attachmentsList = $(this.formAttachmentsListSelector);
  }

  constructor(modalDialogHolderRole) {
    this.initSelectors();
    this.initVariables(modalDialogHolderRole);

    // Append real attachment inputs to holder
    this.realAttachmentInputs.html(this.fileRealInputsTemplate());
    this.newAttachmentModalDialog.find(this.formFileInputLabelSelector).attr('for', `${this.formFileInputLabelForAttribute}-${this.index}`);
    this.formInputsHolder.attr('data-index', this.index + 1);

    this.bindSubmitHandler();
  }

  bindSubmitHandler() {
    $(this.formSubmitAttachmentButtonSelector).click((e) => {
      e.preventDefault();
      if (this.loading) { return; }

      const errorsHolder = $(e.target).closest('.file-upload-modal').find('.attachment-error-message');
      if (errorsHolder.html().length > 0) { return; }

      const attachedFiles = this.realAttachmentInputs.find('@tackable-file-field')[0].files;
      if (attachedFiles.length === 0) {
        errorsHolder.html(`<p>${this.realAttachmentInputs.attr('data-empty-error-message')}</p>`);
        return;
      }

      this.changeModalActionsAccess(e.target, true);
      this.uploadFiles(attachedFiles).then(({ success, template }) => {
        if (success) { return this.saveInputsAndCloseModal(template); }
        errorsHolder.html(template);
        this.changeModalActionsAccess(e.target);
      });
    });
  }

  fileInfo() {
    const description = this.realAttachmentInputs.find(this.formAttachmentRealDescriptionSelector).attr('value');
    const isPublic = this.realAttachmentInputs.find(this.formAttachmentRealIsPublicSelector).attr('value');
    const fileNames = [];

    const filesFromInput = this.realAttachmentInputs.find('@tackable-file-field')[0].files;
    Array.prototype.forEach.call(filesFromInput, file => (fileNames.push(file.name)));
    const name = fileNames.join(', ');

    return this.fileInfoTemplate(name, description, isPublic);
  }

  async uploadFiles(files) {
    const description = this.newAttachmentModalDialog.find(this.formAttachmentFakeDescriptionSelector).val();
    const publicInput = this.newAttachmentModalDialog.find(this.formAttachmentFakeIsPublicSelector);
    const isPublic = publicInput.attr('type') === 'checkbox' ? publicInput.is(':checked') : publicInput.val();

    const names = [];
    for (let i = 0; i < files.length; i++) {
      const response = await this.makeUploadRequest(files[i], description, isPublic);
      const { data: { status, id } } = response;
      if (status === 'success') {
        this.appendTempAttachmentIdInput(id);
        names.push(files[i].name);
      } else {
        this.realAttachmentInputs.find('@temp-attachments')[0].innerHTML = '';
        return { success: false, template: `<p>${i18n.t('errors.file_loading')}: ${files[i].name}</p>` };
      }
    }

    return { success: true, template: this.fileInfoTemplate(names.join(', '), description, isPublic) };
  }

  saveInputsAndCloseModal(template) {
    this.attachmentsList.append(template);
    this.attachmentsList.removeClass('hidden');

    this.realAttachmentInputs.find('@tackable-file-field').remove();
    this.formInputsHolder.append(this.realAttachmentInputs.children());

    ModalDialog.list[this.formFileUploadModalDialogSelector].hide();
  }

  makeUploadRequest(file, description, isPublic) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    const config = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': csrfToken && csrfToken.getAttribute('content'),
        ResponseFormat: 'serialized_object',
      },
      validateStatus(status) { return status >= 200 && status < 504; },
    };
    const url = tempAttachmentsPath();
    const form = new FormData();

    form.append('temp_attachment[attachment][attachment]', file);
    form.append('temp_attachment[attachment][description]', description);
    form.append('temp_attachment[attachment][is_public]', isPublic);
    return axios.post(url, form, config);
  }

  changeModalActionsAccess(submitBtn, disabled) {
    this.loading = disabled;

    submitBtn.toggleAttribute('disabled', disabled);
    $(this.formFileInputLabelSelector).toggleClass('disabled', disabled);

    const publicCheckbox = $(this.formAttachmentFakeIsPublicSelector).closest('.custom-checkbox')[0];
    if (publicCheckbox) { publicCheckbox.toggleAttribute('disabled', disabled); }

    if (disabled) {
      submitBtn.textContent = i18n.t('buttons.loading');
    } else {
      submitBtn.textContent = i18n.t('buttons.upload');
    }
  }

  // overridable methods

  fileRealInputsTemplate() { this; }

  fileInfoTemplate(name, description, isPublic) { this; }

  initSelectors() {
    this.formAttachmentInputHolderSelector;
    this.formRealAttachmentFieldsSelector;
    this.formAttachmentsListSelector;
    this.formFileInputLabelSelector;
    this.formFileInputLabelForAttribute;
    this.formSubmitAttachmentButtonSelector;
    this.formFileUploadModalDialogSelector;
    this.formAttachmentFakeDescriptionSelector;
    this.formAttachmentFakeIsPublicSelector;
    this.formAttachmentRealDescriptionSelector;
    this.formAttachmentRealIsPublicSelector;
  }
}

export default FormAttachments;
